export default {
  public: {
    close: "關閉",
    LogoText: "旅遊百匯 TRAVEL BUFFET",
    nextpage: "下一步",
    country: "國家",
    city: "地區",
    selectcountry: "選取旅遊地區",
    selecttext: "請選擇",
    all: "全部",
    logout: "登出",
    login: "登入",
    delete: "刪除行程",
    success: "成功",
    danger: "失敗",

  },
  alertbox: {
    EditSchedule: "修改行程標題",
  },
  schedule: {
    QRcode: {
      public: "公開",
      share: "分享"
    },
    addDay:"新增一天",
    scheduleLocation: "地區",
    scheduleShareTab: "分享",
    DayStartTime: "開始時間",
    EditStartTimeTitle: "調整時間",
    MapView: {
      outSearchInfo: "搜尋",
      outSearchInfoMap: "Map",
      inAddPoint: "已加入"
    },
    ScheduleInfo: {
      updatetitle: "編輯行程",
      title: "標題",
    },
    shareMethod: {
      only_read: "行程唯獨",
      isEdit: "行程可編輯"
    },
    Tabs: {
      MySchedule: "自建行程",
      ShareSchedule: "分享行程"
    },
    DesignMember: "設計人員",
    narrow: "縮小",
    pointInfoEdit: "修改地點資訊",
    researcharea: "重新搜尋地區",
    pointname: "景點名稱",
    settingpoint: "輸入地址",
    addchoseDay: "加入第幾天",
    choseKid: "加入類別",
    stopTime: "停留時間",
    startTime: "開始時間",
    addInSchedule: "加入行程",
    myschedule: "我的行程",
    searchlist: "搜尋列表",
    title: "行程",
    peole: "人",
    daytext: "天",
    insert: "新增行程",
    update: "修改行程",
    backpage: "上一頁",
    parallelScheduleChose:"並列行程(選填)",
    SearchBox: {
      google: "搜尋Google",
      preferences: "搜尋自家"
    },
    Confirm: {
      deleteTitle: "移除確認",
      deleteDay: "是否要移除這天：",
      deleteSchedule: "是否要刪除這",
    },
    selectkid: {
      food: "食",
      traffic: "行",
      point: "遊",
      fun: "購",
      Morning: "早",
      Afternoon: "午",
      Night: "晚",
      StayUpLate: "宵",
      live: "住",
    },
    text: {
      point: "景點",
      food: "餐廳",
      live: "住宿",
      traffic: "交通",
      fun: "商店",
      favorite: "最愛",
      Preferences: "偏好",
      GoogleSearch: "Google",
      firstday: "第一天 ",
      nowprice: "目前預算",
      maxprice: "最高預算",
      stop: "停留",
      hour: "小時",
      km: "公里",
      distance: "距離",
      min: "分",
      address: "地址",
      info:"簡介",
      rating: "評價",
      latlng: "經緯度",
      types: "分類",
      moveTime: "移動時間",
      auto: "系統產生",
      setting: "自訂",
      autoreportGPS: "自動回報位置",
      pointInfo: "地點資訊",
      trafficInfo: "交通資訊",
      isnoCheckUpdate:"儲存",
      alerttextfornoUpdate:"尚未存擋，分享資訊可能會是舊的！",
      checksave:"點擊存擋",
      placeLogin:"點擊登入會員",
      ParallelSchedule:"並列行程"
    },
    Paylist:{
      Title:"消費列表",
      marksList:"註記列表",
      paylist:"額外消費",
      Morning: "早",
      Afternoon: "午",
      Night: "晚",
      // StayUpLate: "宵夜",
      live: "住",
      MainMethod:"主要",
      ParallelMethod:"或"
    }
  },
  addSchedule: {
    schedulename: "行程名稱",
    scheduleStart: "開始日期",
    scheduleTotalDay: "遊玩天數",
    scheduleEnd: "結束日期",
    shareMethod: "分享模式",
    scheduleFirst: "第一天遊玩地區",
    scheduleSelectDate: "選擇日期",
    scheduleSelectFirst: "選擇第一天遊覽地",
    scheduleAddSchedule: "新增行程",
    scheduleFirstQuitSelect: "快速篩選",
    
  },
  Login: {
    LoginTitle: "登入視窗",
    AuthGoogle: "使用Google登入",
    forgetpassword: "忘記密碼?",
    LoginSubmit: "登入",
    newsignpre: "新會員",
    signtext: "註冊",
    loginerror: "登入錯誤",
    loginsuccess: "登入成功",
    logoutsuccess: "登出成功",
    checkedYouKey: "輸入認證碼",
    checkKeycontext: "請於您輸入的信箱:{email},收取您的認證碼",
    changepw: "輸入密碼",
    doublechangepw: "請再輸入一次密碼",
  },
  resign: {
    accountbox: "修正帳號/密碼",
    submittext: "註冊",
    finishbox: "最後確認",
    needbox: "必填區",
    optionalbox: "選填區",
    pretext: "上一步",
    email: "電子郵件",
    password: "密碼",
    resigngoogle: "使用Google註冊",
    connectgoogle: "連結Goole",
    noconnectgoogle: "",
    name: "名字",
    engname: "英文名字",
    phone: "電話",
    postcode: "郵遞區號",
    location: "地址",
    gender_id: "性別",
    birth: "生日",
    gender_id: "性別",
    nopublic: "不公開",
    male: "男",
    female: "女",
    engnage: "英文名字",
    holder: {
      password: "輸入您的密碼",
      email: "請輸入有效的Email",
      postcode: "請選擇您的郵遞區號",
      gender_id: "選擇性別"
    },
    ismember: "您已經註冊過此帳號"
  },
  Birth: {
    holder: {
      year: "請選擇年份",
      month: "請選擇月份",
      date: "請選擇日期"
    }
  }
}